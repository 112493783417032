var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$store.state.resturant.favorite_menu)+" ")]),_c('v-divider'),_c('v-carousel',{staticStyle:{"height":"443px"},attrs:{"hide-delimiters":""},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((_vm.favorites),function(product){return _c('v-carousel-item',{key:product.id},[_c('v-sheet',{attrs:{"height":"100%","width":"100%","tile":""}},[_c('div',{staticClass:"fill-height align-center justify-center",attrs:{"height":"100%"}},[_c('v-card',{staticClass:"transparent px-md-15",attrs:{"disabled":(product.timed &&
                !_vm.$service.isInTime(product.from, product.to)) ||
              !product.isActive,"flat":""}},[_c('v-container',{staticClass:"mx-md-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_c('span',{style:(_vm.$service.getStyle(product.design).name)},[_vm._v(" "+_vm._s(product.title))])]),_c('span',{style:(_vm.$service.getStyle(product.design).name)},[_vm._v(" "+_vm._s(_vm.$service.formatNumber(product.price))+" "+_vm._s(_vm.$store.state.resturant.currency)+" ")]),_c('div',{staticClass:"subtitle-1",style:(_vm.$service.getStyle(product.design).description)},[_vm._v(" "+_vm._s(product.content)+" ")]),(product.timed)?_c('span',[_vm._v(" متوفر من "+_vm._s(_vm.$service.to12Fromat(product.from))+" الى "+_vm._s(_vm.$service.to12Fromat(product.to))+" ")]):_vm._e(),(
                      (product.timed &&
                        !_vm.$service.isInTime(product.from, product.to)) ||
                      !product.isActive
                    )?_c('span',{staticClass:"error--text"},[_vm._v(" غير متوفر الان ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-btn',{attrs:{"disabled":(product.timed &&
                          !_vm.$service.isInTime(product.from, product.to)) ||
                        !product.isActive,"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleDialog(product.category.id, product.id)}}},[_c('v-icon',[_vm._v("mdi-cart-plus")]),_vm._v(" اضافة الى السلة ")],1)],1)]),_c('v-col',{staticStyle:{"border-radius":"4px !important"},attrs:{"cols":"12","md":"6"}},[(product.photo != null)?[_c('v-img',{staticClass:"shadow",staticStyle:{"border-radius":"4px"},attrs:{"rounded":"","src":_vm.$service.url + product.photo[0].url.replace('/uploads/','/uploads/w_400/'),"height":"400px","width":"100%"}})]:_c('v-img',{staticClass:"shadow",staticStyle:{"border-radius":"4px"},attrs:{"rounded":"","src":_vm.$service.url + _vm.$store.state.resturant.logo.url,"height":"400px","width":"100%"}}),_c('v-divider',{staticClass:"d-flex d-md-none"})],2)],1)],1)],1)],1)])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }