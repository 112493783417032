<template>
  <v-card outlined>
    <v-card-title> {{$store.state.resturant.favorite_menu}} </v-card-title>
    <v-divider></v-divider>
    <v-carousel style="height: 443px" hide-delimiters v-model="carousel">
      <v-carousel-item
        v-for="(product) in favorites"
        :key="product.id"
      >
        <v-sheet height="100%" width="100%" tile>
          <div height="100%" class="fill-height align-center justify-center">
            <v-card
              class="transparent px-md-15"
              :disabled="
                (product.timed &&
                  !$service.isInTime(product.from, product.to)) ||
                !product.isActive
              "
              flat
            >
              <v-container fluid class="mx-md-10">
                <v-row align="center" justify="space-between">
                  <v-col cols="12" md="6">
                    <h1>
                      <span :style="$service.getStyle(product.design).name">
                        {{ product.title }}</span
                      >
                    </h1>
                    <span :style="$service.getStyle(product.design).name">
                      {{ $service.formatNumber(product.price) }}
                      {{ $store.state.resturant.currency }}
                    </span>
                    <div
                      :style="$service.getStyle(product.design).description"
                      class="subtitle-1"
                    >
                      {{ product.content }}
                    </div>
                    <span v-if="product.timed">
                      متوفر من
                      {{ $service.to12Fromat(product.from) }}
                      الى
                      {{ $service.to12Fromat(product.to) }}
                    </span>
                    <span
                      v-if="
                        (product.timed &&
                          !$service.isInTime(product.from, product.to)) ||
                        !product.isActive
                      "
                      class="error--text"
                    >
                      غير متوفر الان
                    </span>
                    <div class="d-flex justify-end mt-2">
                      <v-btn
                        :disabled="
                          (product.timed &&
                            !$service.isInTime(product.from, product.to)) ||
                          !product.isActive
                        "
                        color="primary"
                        @click.stop="toggleDialog(product.category.id, product.id)"
                      >
                        <v-icon>mdi-cart-plus</v-icon>
                        اضافة الى السلة
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" style="border-radius: 4px !important">
                    <template v-if="product.photo != null">
                      <v-img
                        rounded
                        class="shadow"
                        style="border-radius: 4px"
                        :src="$service.url + product.photo[0].url.replace('/uploads/','/uploads/w_400/')"
                        height="400px"
                        width="100%"
                      >
                      </v-img>
                    </template>
                    <v-img
                      v-else
                      rounded
                      class="shadow"
                      style="border-radius: 4px"
                      :src="$service.url + $store.state.resturant.logo.url"
                      height="400px"
                      width="100%"
                    ></v-img>
                    <v-divider class="d-flex d-md-none"></v-divider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
export default {
  props: {
    favorites: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
        carousel:0
    }
  },
  methods:{
    toggleDialog(categoryId, productId) {
      this.$root.$emit("toggleFavDialog", { categoryId, productId });
    },
  }
};
</script>