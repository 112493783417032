<template>
  <div>
    <v-container>
      <div
        class="
          d-flex
          justify-md-space-between
          flex-column flex-md-row
          align-center
        "
      >
        <div
          class="
            d-flex
            align-center
            justify-center
            text-md-right text-center
            flex-column flex-md-row
          "
        >
          <img
            :src="$service.url + $store.state.resturant.logo.url"
            height="120"
            alt=""
            srcset=""
            class="rounded-lg"
            width="120"
          />
          <div class="mx-md-3 my-3 mt-md-0">
            <h3>{{ $store.state.resturant.title }}</h3>
            <p class="">
              {{ $store.state.resturant.description }}
            </p>
          </div>
        </div>
        <div class="text-">
          <h4>
            <v-icon class="mx-1">mdi-map-marker</v-icon>
            {{ $store.state.resturant.address }}
          </h4>
          <h4>
            <v-icon class="mx-1">mdi-clock</v-icon>
            مفتوح من
            {{ $service.to12Fromat($store.state.resturant.open.from) }} الى
            {{ $service.to12Fromat($store.state.resturant.open.to) }}
          </h4>
          <h4>
            <v-icon class="mx-1">mdi-phone</v-icon>
            <a dir="ltr" :href="`phone:${$store.state.resturant.phone}`">
              {{ $store.state.resturant.phone }}
            </a>
          </h4>
        </div>
      </div>
      <v-divider class="my-2"></v-divider>
      <h6 class="text-center">
        {{ $store.state.resturant.content }}
      </h6>
      <favorite v-if="favorites.length > 0" :favorites="favorites"></favorite>
      <v-row>
        <v-col md="2" cols="12">
          <v-list>
            <template v-for="(category, i) in categories">
              <v-list-item
                :color="selectedCategory == i ? 'primary' : ''"
                :key="'cat' + category.id"
                v-if="category.status == 'enabled'"
                :value="category.id"
                @click="selectedCategory = i"
                :class="selectedCategory == i ? 'v-list-item--active' : ''"
              >
                <v-list-item-avatar size="75">
                  <v-img
                    v-if="category.photo != null"
                    contain
                    :src="$service.url + category.photo.url"
                  ></v-img>
                  <v-icon v-else> mdi-food </v-icon>
                </v-list-item-avatar>
                <v-list-item>
                  <v-list-item-title>
                    {{ category.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item></template
            >
          </v-list>
        </v-col>
        <v-col cols="12" md="9">
          <v-row class="my-3">
            <template
              v-for="(product, productIndex) in categories[selectedCategory]
                .products"
            >
              <v-col
                cols="12"
                md="6"
                :key="product.id"
                v-if="product.status == 'enabled'"
              >
                <v-card
                  class="transparent"
                  :disabled="
                    (product.timed &&
                      !$service.isInTime(product.from, product.to)) ||
                    !product.isActive
                  "
                  flat
                >
                  <v-container>
                    <v-row align="center" justify="space-between">
                      <v-col cols="12" md="6">
                        <h1>
                          <span :style="$service.getStyle(product.design).name">
                            {{ product.title }}</span
                          >
                        </h1>
                        <span :style="$service.getStyle(product.design).name" :class="parseInt(product.discount) > 0 ? 'strike' : ''">
                          {{ $service.formatNumber(product.price) }}
                          <span v-if="parseInt(product.discount) < 0">
                          {{ $store.state.resturant.currency }}
    
                          </span>
                        </span>
                        <span :style="$service.getStyle(product.design).name" v-if="parseInt(product.discount) > 0" class="mx-2">
                          {{ $service.formatNumber($service.afterDiscount(product)) }}
                          {{ $store.state.resturant.currency }}
                        </span>
                        <div
                          :style="$service.getStyle(product.design).description"
                          class="subtitle-1"
                        >
                          {{ product.content }}
                        </div>
                        <span v-if="product.timed">
                          متوفر من
                          {{ $service.to12Fromat(product.from) }}
                          الى
                          {{ $service.to12Fromat(product.to) }}
                        </span>
                        <span
                          v-if="
                            (product.timed &&
                              !$service.isInTime(product.from, product.to)) ||
                            !product.isActive
                          "
                          class="error--text"
                        >
                          غير متوفر الان
                        </span>
                        <div class="d-flex justify-end mt-2">
                          <v-btn
                            :disabled="
                              (product.timed &&
                                !$service.isInTime(product.from, product.to)) ||
                              !product.isActive
                            "
                            color="primary"
                            @click="
                              toggleDialog(selectedCategory, productIndex)
                            "
                          >
                            <v-icon>mdi-cart-plus</v-icon>
                            اضافة الى السلة
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <template v-if="product.photo != null">
                          <v-img
                            rounded
                            class="shadow"
                            style="border-radius: 4px"
                            :src="$service.url + product.photo[0].url.replace('/uploads/','/uploads/w_400/')"
                            height="200px"
                            width="100%"
                          >
                          </v-img>
                        </template>
                        <v-img
                          v-else
                          rounded
                          class="shadow"
                          style="border-radius: 4px"
                          :src="$service.url + $store.state.resturant.logo.url"
                          height="200px"
                          width="100%"
                        ></v-img>
                        <v-divider class="d-flex d-md-none"></v-divider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <v-fab-transition>
        <v-btn
          color="primary"
          x-large
          fixed
          bottom
          left
          style="z-index: 6"
          @click="
            (e) => {
              if ($store.state.cart.length > 0) {
                $store.state.drawer = true;
              } else {
                $store.state.error = true;
              }
            }
          "
        >
          <v-icon>mdi-cart</v-icon>
          تأكيد الطلب ( {{ $service.formatNumber(orderPrice) }} د.ع )
        </v-btn>
      </v-fab-transition>
    </v-container>
    <div class="my-10"></div>
    <v-divider class="mt-10"></v-divider>
    <h4 class="text-center grey--text mt-10">
      Copyright &copy; {{ new Date().getFullYear() }} -
      <a href="https://ft.iq" target="_blank">FutureTech</a>
    </h4>
  </div>
</template>
<script>
import favorite from '../../components/favorite.vue';
export default {
  components: { favorite },
  props: {
    favorites: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedCategory: 0,
      forceUpdate: 0,
      carousel: 0,
    };
  },
  mounted() {
    this.$root.$on("refresh", () => {
      this.$forceUpdate();
      this.forceUpdate++;
      
    });
  },
  computed: {
    orderPrice() {
      return this.$store.state.cart.reduce((acc, product) => {
        return acc + product.finalPrice;
      }, 0);
    },
  },
  methods: {
    toggleDialog(categoryIndex, productIndex) {
      this.$root.$emit("toggleDialog", { categoryIndex, productIndex });
    },

    cols(category) {
      switch (parseInt(category.cols)) {
        case 12:
          return 3;
        case 8:
          return 4;
        default:
          return 12;
      }
    },
  },
};
</script>
<style>
.shadow {
  box-shadow: 16px 11px 9px -4px rgb(0 0 0 / 49%) !important;
}
</style>