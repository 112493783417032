<template>
  <div>
    <div
      class="overlay"
      v-if="
        !$service.isInTime(
          this.$store.state.resturant.open.from,
          this.$store.state.resturant.open.to
        )
      "
    >
      <div
        style="height: 400px"
        class="d-flex justify-center align-center flex-column"
      >
        <h1>عذراً، المطعم مغلق الان</h1>
        <h2 class="mt-10">
          <v-icon class="mx-1">mdi-clock</v-icon>
          مفتوح من
          {{ $service.to12Fromat($store.state.resturant.open.from) }} الى
          {{ $service.to12Fromat($store.state.resturant.open.to) }}
        </h2>
      </div>
    </div>
    <regular
      :key="1"
      v-if="this.$store.state.resturant.menuTheme == 'regular'"
      :categories="categories"
      :favorites="favorites"
    />
    <List
      :key="2"
      v-else-if="this.$store.state.resturant.menuTheme == 'list'"
      :categories="categories"
      :favorites="favorites"
    />
    <Side
      :key="3"
      v-else-if="this.$store.state.resturant.menuTheme == 'side'"
      :categories="categories"
      :favorites="favorites"
    />
    <v-navigation-drawer
      v-if="$store.state.resturant != null"
      :width="
        $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'
          ? '100%'
          : '25%'
      "
      v-model="$store.state.drawer"
      temporary
      app
    >
      <v-container>
        <v-card-title>
          <h2>تأكيد الطلب</h2>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="$store.state.drawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-list class="mt-4">
          <v-list-item
            v-for="(orderProduct, index) in $store.state.cart"
            :key="orderProduct.product.id"
          >
            <v-list-item-title>
              <div class="d-flex align-baseline justify-space-between">
                <div>
                  <h4>
                    {{ orderProduct.product.title }}
                    <span
                      v-if="orderProduct.additons.length > 0"
                      class="caption"
                    >
                      ({{ orderProduct.additons.length }} اضافات)
                    </span>
                  </h4>
                  <small>
                    العدد:
                    {{ orderProduct.quantity }}
                  </small>
                  <br />
                  <small>
                    ملاحظات:
                    {{ orderProduct.note }}
                  </small>
                </div>

                <div>
                  <span>
                    {{ $service.formatNumber(orderProduct.finalPrice) }}
                    {{ $store.state.resturant.currency }}
                  </span>
                  <br />
                  <v-btn @click="deleteFromCart(index)" color="error" text>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-divider
                v-if="orderProduct.additons.length > 0"
                class="my-3"
              ></v-divider>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider class="my-2"></v-divider>

        <h3>معلوماتك والعنوان</h3>
        <v-form ref="form" class="mt-2" v-model="valid">
          <v-select
            outlined
            label="المنطقة"
            v-model="order.area"
            :rules="[$rules.required()]"
            :items="areas"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-text-field
            outlined
            v-model="order.fullName"
            :rules="[$rules.required()]"
            label="الاسم"
            required
          >
          </v-text-field>
          <v-text-field
            outlined
            v-model="order.phone"
            :rules="[$rules.required()]"
            label="رقم الهاتف"
            required
            dir="ltr"
            v-mask="'#### ### ####'"
          ></v-text-field>
          <v-text-field
            outlined
            v-model="order.address"
            :rules="[$rules.required()]"
            label="العنوان"
            required
          >
          </v-text-field>
          <v-textarea
            outlined
            label="وصف العنوان"
            v-model="order.nearBy"
            placeholder="مثل: اقرب نقطة دالة"
          >
          </v-textarea>
          <v-textarea
            outlined
            label="ملاحظات"
            v-model="order.note"
          ></v-textarea>
          <v-btn @click="mapDialog = !mapDialog" color="primary">
            <v-icon> mdi-map-marker-plus </v-icon>
            اضغط هنا لتحديد المكان على الخريطة
          </v-btn>
          <p class="mt-2">
            المجموع:
            <span class="font-weight-bold">
              {{ $service.formatNumber(orderPrice) }}
              {{ $store.state.resturant.currency }}
            </span>
          </p>
          <p>
            رسوم التوصيل:
            <span class="font-weight-bold" v-if="order.area">
              {{ $service.formatNumber(deliveryFee) }}
              {{ $store.state.resturant.currency }}
            </span>
          </p>
          <p>
            الاجمالي:
            <span class="font-weight-bold">
              {{ $service.formatNumber(orderPrice + deliveryFee) }}
              {{ $store.state.resturant.currency }}
            </span>
          </p>
          <div style="height: 150px">
            <v-btn
              color="primary"
              block
              :disabled="!valid"
              @click="
                () => {
                  $store.state.confirmDialog = true;
                  drawer = false;
                }
              "
            >
              ارسال الطلب
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-navigation-drawer>
    <v-dialog
      v-if="$store.state.resturant != null"
      width="700px"
      v-model="mapDialog"
      persistent
    >
      <v-card>
        <v-card-title>
          اضغط على الخريطة لتحديد المكان او قم بسحب الدبوس
        </v-card-title>
        <v-card-text>
          <GmapMap
            ref="mapRef"
            :center="center"
            :zoom="16"
            @click="mark"
            map-type-id="terrain"
            style="width: 100%; height: 50vh"
          >
            <GmapMarker
              ref="myMarker"
              :draggable="true"
              :position="
                google && new google.maps.LatLng(order.lat, order.long)
              "
            />
          </GmapMap>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="mapDialog = false">
            اغلاق
          </v-btn>
          <v-btn color="primary" @click="mapDialog = false"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="$store.state.resturant != null"
      width="500"
      persistent
      v-model="$store.state.addToCartDialog"
      :close-on-content-click="false"
    >
      <v-card v-if="productIndex > -1" light>
        <v-card-title>
          اضافة {{ categories[categoryIndex].products[productIndex].title }} الى
          السلة
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            @click="toggleDialog(categoryIndex, productIndex)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <v-form ref="addtocart">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="orderProduct.quantity"
                  label="الكمية"
                  min="1"
                  class="text-center"
                  v-mask="'##'"
                  style=""
                  @input="updateFinalPrice(categoryIndex, productIndex)"
                  pattern="[0-9]"
                  type="number"
                >
                  <!-- plus buttom -->
                  <template v-slot:append-outer>
                    <v-btn
                      color="primary"
                      outlined
                      @click="
                        changeQuantity('plus', categoryIndex, productIndex)
                      "
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <!-- minus buttom -->
                  <template v-slot:prepend>
                    <v-btn
                      color="primary"
                      outlined
                      @click.close="
                        changeQuantity('minus', categoryIndex, productIndex)
                      "
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                v-if="
                  categories[categoryIndex].products[productIndex].additons
                    .length > 0
                "
                cols="12"
              >
                <h4>الاضافات</h4>
                <v-checkbox
                  v-for="addition in categories[categoryIndex].products[
                    productIndex
                  ].additons.filter((v) => !v.priced)"
                  :key="addition.id"
                  row
                  hide-details="auto"
                  :label="addition.text"
                  @change="
                    (e) => {
                      toggleAddition(e, categoryIndex, productIndex, addition);
                    }
                  "
                ></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <v-radio-group
                  row
                  @change="
                    (e) => {
                      toggleAddition(
                        e,
                        categoryIndex,
                        productIndex,
                        categories[categoryIndex].products[
                          productIndex
                        ].additons
                          .filter((v) => v.priced)
                          .find((v) => v.id == e)
                      );
                    }
                  "
                >
                  <v-radio
                    :key="addition.id"
                    v-for="addition in categories[categoryIndex].products[
                      productIndex
                    ].additons.filter((v) => v.priced)"
                    hide-details="auto"
                    :label="addition.text + ` ( +${addition.price} )`"
                    :value="addition.id"
                  />
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <h4>الملاحظات</h4>
                <v-textarea
                  v-model="orderProduct.note"
                  outlined
                  rows="3"
                  placeholder="مثال: بدون فلفل، صلصة اضافية"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  block
                  @click="
                    addToCart(
                      categories[categoryIndex].products[productIndex],
                      categoryIndex,
                      productIndex
                    )
                  "
                >
                  <v-icon>mdi-cart-plus</v-icon>
                  اضافة الى السلة (
                  {{ $service.formatNumber(orderProduct.finalPrice) }}
                  {{
                    categories[categoryIndex].products[productIndex].currency
                  }}
                  )
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="$store.state.resturant != null"
      v-model="$store.state.confirmDialog"
      persistent
      :overlay="false"
      max-width="900px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <h3>
            <v-icon>mdi-check-circle</v-icon>
            يرجى التأكد من الطلب قبل ارساله
          </h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.confirmDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-container v-if="$store.state.confirmDialog">
          <v-row>
            <v-col cols="12" md="6">
              <p>
                الاسم الكامل:
                <span class="font-weight-bold">
                  {{ order.fullName }}
                </span>
              </p>
              <p>
                رقم الهاتف:
                <span class="font-weight-bold">
                  {{ order.phone }}
                </span>
              </p>
              <p>
                العنوان:
                <span class="font-weight-bold">
                  {{ order.address }}
                </span>
              </p>
              <p>
                وصف العنوان الاضافي:
                <span class="font-weight-bold">
                  {{ order.nearBy }}
                </span>
              </p>
              <p>
                ملاحظات:
                <span class="font-weight-bold">
                  {{ order.note }}
                </span>
              </p>
              <p>
                المجموع:
                <span class="font-weight-bold">
                  {{ orderPrice }}
                  {{ $store.state.resturant.currency }}
                </span>
              </p>
              <p>
                رسوم التوصيل:
                <span class="font-weight-bold">
                  {{ deliveryFee }}
                  {{ $store.state.resturant.currency }}
                </span>
              </p>
              <p>
                الاجمالي:
                <span class="font-weight-bold">
                  {{ orderPrice + deliveryFee }}
                  {{ $store.state.resturant.currency }}
                </span>
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <p>المنتجات:</p>
              <v-row>
                <v-col
                  cols="12"
                  v-for="product in $store.state.cart"
                  :key="product.id"
                >
                  <v-card outlined>
                    <v-card-title>
                      <p>{{ product.product.title }}</p>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          style="padding-top: 0px; padding-bottom: 0px"
                          cols="12"
                          md="6"
                        >
                          <p>
                            الكمية:
                            <span class="font-weight-bold">
                              {{ product.quantity }}
                            </span>
                          </p>
                        </v-col>
                        <v-col
                          style="padding-top: 0px; padding-bottom: 0px"
                          cols="12"
                          md="6"
                        >
                          <p>
                            السعر:
                            <span class="font-weight-bold">
                              {{ product.finalPrice }}
                            </span>
                          </p>
                        </v-col>
                        <v-col
                          style="padding-top: 0px; padding-bottom: 0px"
                          cols="12"
                          v-if="product.additons.length > 0"
                        >
                          <p>الاضافات:</p>
                          <v-row>
                            <v-col
                              style="padding-top: 0px; padding-bottom: 0px"
                              v-for="addition in product.additons"
                              :key="addition.id"
                              cols="6"
                            >
                              <p>
                                {{ addition.text }}
                                <span class="font-weight-bold">
                                  ( +{{ addition.price }} )
                                </span>
                              </p>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="d-flex justify-end mt-2">
            <v-btn
              color="error"
              class="mx-4"
              @click="
                $store.state.confirmDialog = false;
                drawer = true;
              "
            >
              <v-icon>mdi-alert-circle</v-icon>
              رجوع وتعديل
            </v-btn>
            <v-btn
              color="primary"
              @click="
                $store.state.confirmDialog = false;
                submitOrder();
              "
            >
              <v-icon>mdi-check</v-icon>
              تأكيد الطلب
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
      app
      right
      top
      color="error"
      v-model="$store.state.error"
      timeout="2000"
    >
      يجب اختيار منتج واحد على الاقل
    </v-snackbar>
  </div>
</template>
<script>
import regular from "./styles/regular.vue";
// import moment from "moment";
import { gmapApi } from "vue2-google-maps";
import List from "./styles/list.vue";
import Side from "./styles/side.vue";
export default {
  computed: {
    google: gmapApi,
    orderPrice() {
      return this.$store.state.cart.reduce((acc, product) => {
        return acc + product.finalPrice;
      }, 0);
    },
    deliveryFee() {
      if (this.order.area) {
        return this.areas.find((v) => v.id == this.order.area).price;
      } else {
        return 0;
      }
    },
    center(){
      return { lat: parseFloat(this.order.lat), lng: parseFloat(this.order.long) }
    }
  },
  components: {
    regular,
    List,
    Side,
  },
  data() {
    return {
      drawer: false,
      categories: [],
      cart: [],
      menus: [],
      error: false,
      order: {
        fullName: "",
        phone: "",
        address: "",
        nearBy: "",
        area: null,
        note: "",
        long: 0,
        lat: 0,
        quantity: "",
        order_products: [],
        resturant: "",
      },
      valid: false,
      mapDialog: false,
      orderProduct: {
        note: "",
        product: {},
        additons: [],
        finalPrice: 0,
        quantity: 1,
      },
      forceUpdate: 0,
      productIndex: -1,
      categoryIndex: -1,
      confirmDialog: false,
      addToCartDialog: false,
      themes: ["regular", "list", "side"],
      favorites: [],
      areas: [],
    };
  },
  async created() {
    var host = window.location.host;
    var subdomain = host.split(".")[0];
    // var subdomain = this.$route.params.rest;
    let resturnt = await this.$http.get("/resturants", {
      params: {
        filters: {
          url: {
            $eq: subdomain,
          },
        },
        populate: "*",
      },
      paramsSerializer: this.$service.qsParams,
    });

    if (resturnt.data.data[0].blocked) {
      this.$router.push({ path: "/" });
    }

    this.$store.commit("setResturant", resturnt.data.data[0]);

    this.getProducts();
    document.title = this.$store.state.resturant.title;
    if (this.$store.state.resturant.theme == "dark") {
      this.$vuetify.theme.dark = true;
    } else if (this.$store.state.resturant.theme == "light") {
      this.$vuetify.theme.dark = false;
    } else if (this.$store.state.resturant.theme == "custom") {
      this.$vuetify.theme.dark =
        this.$store.state.resturant.customTheme == "dark";
    }
    // set primary color
    this.$vuetify.theme.themes.light.primary =
      this.$store.state.resturant.primaryColor;
    this.$vuetify.theme.themes.dark.primary =
      this.$store.state.resturant.primaryColor;
    // set secondary color
    this.$vuetify.theme.themes.light.secondary =
      this.$store.state.resturant.secondaryColor;
    this.$vuetify.theme.themes.dark.secondary =
      this.$store.state.resturant.secondaryColor;
    this.order.resturant = this.$store.state.resturant.id;
    document.querySelector("link[rel='icon']").href =
      this.$service.url + this.$store.state.resturant.logo;
    if (
      this.$store.state.resturant.menuTheme == null ||
      this.themes.indexOf(this.$store.state.resturant.menuTheme) == -1
    ) {
      this.$store.state.resturant.menuTheme = "regular";
    }
    let res = await this.$http.get("/areas", {
      params: {
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
      },
      paramsSerializer: this.$service.qsParams,
    });
    this.areas = res.data.data;
  },
  mounted() {
    this.$root.$on("toggleDialog", (indexes) => {
      this.toggleDialog(indexes.categoryIndex, indexes.productIndex);
    });
    this.$root.$on("toggleFavDialog", (indexes) => {
      let categoryIndex = this.categories.findIndex(
        (category) => category.id == indexes.categoryId
      );
      let productIndex = this.categories[categoryIndex].products.findIndex(
        (product) => product.id == indexes.productId
      );
      
      this.toggleDialog(categoryIndex, productIndex);
    });
    this.getFavProducts();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.order.long = lng.toString();
          this.order.lat = lat.toString();

          // Do something with the location data, e.g. display on a map
          
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error("Error getting user location:", error);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by this browser.");
    }
  },
  methods: {
    deleteFromCart(index) {
      this.$store.commit("deleteFromCart", index);
    },
    toggleDialog(categoryIndex, productIndex) {
      this.categoryIndex = categoryIndex;
      this.productIndex = productIndex;

      let product = this.categories[categoryIndex].products[productIndex];
      if (!this.addToCart) {
        this.orderProduct = {
          note: "",
          product: {},
          additons: [],
          finalPrice: 0,
          quantity: 1,
        };
      } else {
        this.orderProduct = {
          note: "",
          product: product,
          additons: [],
          finalPrice: this.$service.afterDiscount(product),
          quantity: 1,
        };
      }

      this.$store.state.addToCartDialog = !this.$store.state.addToCartDialog;
      this.$forceUpdate();
    },
    mark(e) {
      this.order.long = e.latLng.lng().toString();
      this.order.lat = e.latLng.lat().toString();
    },
    async submitOrder() {
      try {
        this.order.order_products = this.$store.state.cart.map((product) => {
          return {
            product: product.product.id,
            quantity: product.quantity,
            note: product.note,
            finalPrice: product.finalPrice,
            additons: product.additons,
          };
        });
        
        await this.$http.post("/orders", {
          data: this.order,
        });
        window.location.href = "/done";
      } catch (e) {
      console.log(e);
      }
    },
    toggleAddition(e, categoryIndex, productIndex, addition) {
      if (typeof e == "boolean") {
        if (e) {
          let add = {
            text: addition.text,
            price: addition.price,
            priced: false,
            erp_code: addition.erp_code,
          };
          this.orderProduct.additons.push(add);
        } else {
          this.orderProduct.additons.splice(
            this.orderProduct.additons.indexOf(addition),
            1
          );
        }
      } else if (typeof e == "number") {
        let index = this.orderProduct.additons.findIndex(
          (addition) => addition.priced == true
        );
        if (index != -1) {
          
          this.orderProduct.additons.splice(index, 1);
        }
        let add = {
          text: addition.text,
          price: addition.price,
          priced: true,
          erp_code: addition.erp_code,
        };
        this.orderProduct.additons.push(add);
      }
      this.updateFinalPrice(categoryIndex, productIndex);
    },
    updateFinalPrice(categoryIndex, productIndex) {
      let additionPrice = this.orderProduct.additons.reduce(
        (total, addition) => {
          return total + addition.price;
        },
        0
      );
      this.orderProduct.finalPrice =
        (this.$service.afterDiscount(
          this.categories[categoryIndex].products[productIndex]
        ) +
          additionPrice) *
        this.orderProduct.quantity;

      this.$forceUpdate();
      this.forceUpdate++;
      this.$root.$emit("refresh");
    },
    changeQuantity(type, categoryIndex, productIndex) {
      if (type == "plus") {
        this.orderProduct.quantity++;
        this.orderProduct.finalPrice += this.$service.afterDiscount(
          this.categories[categoryIndex].products[productIndex]
        );
      } else {
        if (this.orderProduct.quantity > 1) {
          this.orderProduct.quantity--;
        }
      }
      this.updateFinalPrice(categoryIndex, productIndex);
      this.$forceUpdate();
    },
    addToCart(product, categoryIndex, productIndex) {
      
      this.$store.state.cart.push(this.orderProduct);
      this.toggleDialog(categoryIndex, productIndex);
      this.$forceUpdate();
    },
    cols(category) {
      switch (parseInt(category.cols)) {
        case 12:
          return 3;
        case 8:
          return 4;
        default:
          return 12;
      }
    },
    async getFavProducts() {
      let products = await this.$http.get("/products", {
        params: {
          populate: "*",
          filters: {
            favorite: {
              $eq: true,
            },
            resturant: {
              id: { $eq: this.$store.state.resturant.id },
            },
          },
        },
        paramsSerializer: this.$service.qsParams,
      });
      
      this.favorites = products.data.data;
    },
    async getProducts() {
      let categories = await this.$http.get("/categories", {
        params: {
          populate: "deep",
          filters: {
            resturant: {
              id: { $eq: this.$store.state.resturant.id },
            },
          },
        },
        paramsSerializer: this.$service.qsParams,
      });
      this.categories = categories.data.data;

      this.categories.forEach((category) => {
        category.products.forEach((product) => {
          product.menu = false;
          product.orderProduct = {
            note: "",
            product: product,
            additons: [],
            finalPrice: parseInt(product.price),
            quantity: 1,
          };
        });
        category.products.sort(
          (a, b) => parseFloat(a.index) - parseFloat(b.index)
        );
      });
    },
  },
  watch: {
    mapDialog(val) {
      this.$store.state.drawer = !val;
    },
    "$store.state.addToCartDialog"(val) {
      if (!val) {
        this.$refs.addtocart.reset();
      }
    },
  },
};
</script>
<style>
.img {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  top: 0;
}

.theme--dark.v-toolbar.v-sheet {
  background-color: rgba(39, 39, 39, 0.842) !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

input[type="number"] {
  text-align: center;
  font-size: 18px;
}

.v-input input {
  max-height: 50px !important;
}

.overlay {
  position: fixed;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background: rgba(29, 29, 29, 0.701);
}
</style>
