<template>
  <div>
    <v-container v-if="$store.state.resturant != null" style="padding-top: 0">
      <div v-if="$store.state.resturant != null">
        <v-parallax
          class="overlay"
          :src="$service.url + $store.state.resturant.background.url"
        >
          <v-row align="end" justify="end">
            <v-col>
              <div
                class="
                  d-flex
                  align-center
                  justify-start
                  my-3
                  flex-column flex-md-row
                "
                v-if="$store.state.resturant != null"
              >
                <img
                  :src="$service.url + $store.state.resturant.logo.url"
                  height="120"
                  alt=""
                  srcset=""
                  class="rounded-lg"
                  width="120"
                />
                <div class="mx-3">
                  <h3>{{ $store.state.resturant.title }}</h3>
                  <p class="">
                    {{ $store.state.resturant.description }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-parallax>
        <div class="my-2">
          <h4>
            <v-icon class="mx-1">mdi-map-marker</v-icon>
            {{ $store.state.resturant.address }}
          </h4>
          <h4>
            <v-icon class="mx-1">mdi-clock</v-icon>
            مفتوح من
            {{ $service.to12Fromat($store.state.resturant.open.from) }} الى
            {{ $service.to12Fromat($store.state.resturant.open.to) }}
          </h4>
          <h4>
            <v-icon class="mx-1">mdi-phone</v-icon>
            <a dir="ltr" :href="`phone:${$store.state.resturant.phone}`">
              {{ $store.state.resturant.phone }}
            </a>
          </h4>
        </div>
        <h4 class="text-center my-5">
          {{ $store.state.resturant.content }}
        </h4>
        <Favorite v-if="favorites.length > 0" :favorites="favorites" />
      </div>

      <v-fab-transition>
        <v-btn
          color="primary"
          x-large
          fixed
          bottom
          left
          style="z-index: 6"
          @click="
            (e) => {
              if ($store.state.cart.length > 0) {
                $store.state.drawer = true;
              } else {
                $store.state.error = true;
              }
            }
          "
        >
          <v-icon>mdi-cart</v-icon>
          تأكيد الطلب ( {{ $service.formatNumber(orderPrice) }} د.ع )
        </v-btn>
      </v-fab-transition>
      <v-row class="mt-10">
        <template v-for="(category, categoryIndex) in categories">
          <v-col
            v-if="category.status == 'enabled'"
            :key="category.id"
            cols="12"
            :md="category.cols"
          >
            <v-card class="transparent" flat>
              <div class="d-flex align-baseline flex-column px-2 py-2">
                <span :style="$service.getStyle(category.design).name">
                  {{ category.title }}
                </span>
                <br />
                <span :style="$service.getStyle(category.design).description">
                  {{ category.content }}
                </span>
                <br />
              </div>
              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <template
                    v-for="(product, productIndex) in category.products"
                  >
                    <v-col
                      cols="12"
                      v-if="product.status == 'enabled'"
                      :key="product.id"
                      :md="cols(category)"
                      style="height: 100%"
                    >
                      <v-card
                        :disabled="
                          (product.timed &&
                            !$service.isInTime(product.from, product.to)) ||
                          !product.isActive
                        "
                        style="
                          display: flex;
                          flex-direction: column;
                          padding: 6px;
                        "
                        :color="
                          $store.state.resturant.theme == 'custom'
                            ? $store.state.resturant.cardColor
                            : ''
                        "
                      >
                        <v-container fluid>
                          <template v-if="product.photo != null">
                            <v-img
                              rounded
                              class="shadow"
                              style="border-radius: 4px"
                              :src="$service.url + product.photo[0].url.replace('/uploads/','/uploads/w_400/')"
                              height="200px"
                              width="100%"
                            >
                            </v-img>
                          </template>
                          <v-img
                            v-else
                            rounded
                            class="shadow"
                            style="border-radius: 4px"
                            :src="
                              $service.url + $store.state.resturant.logo.url
                            "
                            height="200px"
                            width="100%"
                          ></v-img>
                        </v-container>
                        <v-card-title>
                          <div class="d-flex flex-column">
                            <span
                              :style="$service.getStyle(product.design).name"
                            >
                              {{ product.title }}</span
                            >

                            <div
                              :style="
                                $service.getStyle(product.design).description
                              "
                              class="subtitle-1"
                            >
                              {{ product.content }}
                            </div>
                          </div>
                          <v-spacer></v-spacer>
                          <span :style="$service.getStyle(product.design).name" :class="parseInt(product.discount) > 0 ? 'strike' : ''">
                            {{ $service.formatNumber(product.price) }}
                            <span v-if="parseInt(product.discount) < 0">
                            {{ $store.state.resturant.currency }}
      
                            </span>
                          </span>
                          <span :style="$service.getStyle(product.design).name" v-if="parseInt(product.discount) > 0" class="mx-2">
                            {{ $service.formatNumber($service.afterDiscount(product)) }}
                            {{ $store.state.resturant.currency }}
                          </span>
                          <small v-if="product.timed">
                            متوفر من
                            {{ $service.to12Fromat(product.from) }}
                            الى
                            {{ $service.to12Fromat(product.to) }}
                          </small>
                        </v-card-title>
                        <v-container>
                          <span
                            v-if="
                              (product.timed &&
                                !$service.isInTime(product.from, product.to)) ||
                              !product.isActive
                            "
                            class="error--text"
                          >
                            غير متوفر الان
                          </span>
                        </v-container>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            :disabled="
                              (product.timed &&
                                !$service.isInTime(product.from, product.to)) ||
                              !product.isActive
                            "
                            color="primary"
                            @click="toggleDialog(categoryIndex, productIndex)"
                          >
                            <v-icon>mdi-cart-plus</v-icon>
                            اضافة الى السلة
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </template>
      </v-row>
      <div class="my-10"></div>
      <v-divider class="mt-10"></v-divider>
      <h4 class="text-center grey--text mt-10">
        Copyright &copy; {{ new Date().getFullYear() }} -
        <a href="https://ft.iq" target="_blank">FutureTech</a>
      </h4>
    </v-container>
  </div>
</template>
<script>
import Favorite from "../../components/favorite.vue";
export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
    favorites: {
      type: Array,
      required: true,
    },
  },
  computed: {
    orderPrice() {
      return this.$store.state.cart.reduce((acc, product) => {
        return acc + product.finalPrice;
      }, 0);
    },
  },
  components: { Favorite },
  data() {
    return {
      carousel: 0,
    };
  },

  mounted() {
    this.$root.$on("refresh", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    toggleDialog(categoryIndex, productIndex) {
      this.$root.$emit("toggleDialog", { categoryIndex, productIndex });
    },

    cols(category) {
      switch (parseInt(category.cols)) {
        case 12:
          return 3;
        case 8:
          return 4;
        default:
          return 12;
      }
    },
  },
};
</script>
<style >
.img {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  top: 0;
}

.theme--dark.v-toolbar.v-sheet {
  background-color: rgba(39, 39, 39, 0.842) !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

input[type="number"] {
  text-align: center;
  font-size: 18px;
}
</style>